<template>
	<v-snackbar
		:value="active"
		:right="$vuetify.breakpoint.mdAndUp"
		bottom
		:color="dialogColor"
		:timeout="4000"
		style="margin-bottom: 60px"
		@input="close"
	>
		{{ message }}
		<v-btn text @click="close">
			{{ $t('search.close') }}
		</v-btn>
	</v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FeedbackSnackBar',
	computed: {
		dialogColor() {
			return this.success ? 'success' : 'error'
		},
		...mapGetters({
			active: 'snackbar/active',
			success: 'snackbar/success',
			message: 'snackbar/message'
		})
	},
	methods: {
		...mapActions('snackbar', ['close'])
	}
}
</script>
